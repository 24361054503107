import { AssetTable } from 'components/organisms/AssetTable';
import { HomeControls } from 'components/organisms/HomeControls';
import { useState } from 'react';

export const SearchPage = (): JSX.Element => {
  const [hover, setHover] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');

  return (
    <>
      <HomeControls />
      {/* <Row>
        <Tooltip title="Covered Products">
          <Button
            onMouseLeave={(): void => setHover('')}
            onMouseEnter={(): void => setHover('coveredParts')}
            style={{
              color: '#136538',
              backgroundColor: hover === 'coveredParts' || selectedFilter === 'coveredParts' ? '#ECFFF4' : undefined,
              width: 40,
              height: 45,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }}
            value="coveredParts"
            onClick={() => {
              setSelectedFilter('coveredParts');
              if (selectedFilter === 'coveredParts') return setSelectedFilter('');
            }}
            icon={
              <Lottie animationData={checkLottie} loop={true} goTo={50} play={hover === 'coveredParts'} style={{ transform: 'scale(0.6)' }} />
              // </div>
            }
          />
        </Tooltip>
        <Tooltip title="Expiring Soon">
          <Button
            onMouseLeave={(): void => setHover('')}
            onMouseEnter={(): void => setHover('needsAttention')}
            style={{ color: '#C6BA00', backgroundColor: hover === 'needsAttention' || selectedFilter === 'needsAttention' ? '#FFFDD7' : undefined, width: 40, height: 45, borderRadius: 0 }}
            value="needsAttention"
            onClick={() => {
              setSelectedFilter('needsAttention');
              if (selectedFilter === 'needsAttention') return setSelectedFilter('');
            }}
            icon={
              <Row align={'middle'} justify={'center'}>
                <Lottie
                  // segments={[5, 63]}
                  animationData={cautionLottie}
                  loop={true}
                  goTo={25}
                  play={hover === 'needsAttention'}
                  style={{ transform: 'scale(0.85)' }}
                />
              </Row>
            }
          />
        </Tooltip>
        <Tooltip title="Expired">
          <Button
            onMouseLeave={(): void => setHover('')}
            onMouseEnter={(): void => setHover('expired')}
            style={{
              color: '#C70039',
              backgroundColor: hover === 'expired' || selectedFilter === 'expired' ? '#FFEBEB' : undefined,
              width: 40,
              height: 45,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            }}
            value="expired"
            onClick={() => {
              setSelectedFilter('expired');
              if (selectedFilter === 'expired') return setSelectedFilter('');
            }}
            icon={
              <Row align={'middle'} justify={'center'}>
                <Lottie animationData={expiredLottie} loop={true} goTo={30} play={hover === 'expired'} style={{ transform: 'scale(0.58)' }} />
              </Row>
            }
          />
        </Tooltip>
      </Row> */}
      <AssetTable />
      {/* </Space> */}
    </>
  );
};
