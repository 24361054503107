import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteRecordButton } from 'components/atoms/DeleteRecordButton';
import { EditProductButton } from 'components/atoms/EditProductButton';
import { ProductTypeFilter } from 'components/atoms/Filters/ProductFilters/ProductTypeFilter';
import WimsicalError from 'components/atoms/WimsicalError/WimsicalError';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { PageLoader } from 'components/common/PageLoader';
import { useGetParams } from 'hooks/useGetParams';
import { rest } from 'lodash';
import { FC, useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetInstallBasesQuery } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { useGetIntangibleAssetsQuery } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import { ProductResponse, useDeleteProductMutation, useGetProductsQuery } from 'redux/services/captainBreakfast/products/productsApi';
import { useAppSelector } from 'redux/store';
import baseLottie from '../../assets/base.json';
import iaLottie from '../../assets/intangible.json';

export const PartsMasterTable: FC = () => {
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [iconHover, setIconHover] = useState('');

  const [deleteProduct, { isLoading: isDeleting }] = useDeleteProductMutation();
  const [take, setTake] = useState(25);
  const [offset, setOffset] = useState(0);

  const { params } = useGetParams([
    'productNumberContains',
    'descriptionContains',
    'manufacturerNameContains',
    'categoryNameContains',
    'alternateItemIdContains',
    'includeInactiveData',
    'returnInactiveDataOnly',
    'productType'
  ]);

  const { data, isLoading, isFetching, isError, error } = useGetProductsQuery({ ...params, offset, take });
  const { data: assetData, isLoading: isAssetLoaing, isError: isIaError, error: iaError } = useGetIntangibleAssetsQuery({});
  const { data: baseData, isLoading: isInstallBaseLoading, isError: isBaseError, error: baseError } = useGetInstallBasesQuery({});

  const { isImporting, importLoadingMessage } = useAppSelector((state) => state.import);

  const intangibleItemId = searchParams.get('productNumberContains');

  const isIntangibleItem = data?.data.find((item) => item.productNumber === intangibleItemId)?.productType === 'Intangible Asset';

  const totalCount = data?.totalCount ?? 0;

  const isDeleteDisabled = (record: ProductResponse): boolean => {
    const isAssetAssigned = assetData?.data.some((item) => item.product?.productNumber === record.productNumber);
    const isBaseAssigned = baseData?.data.some((item) => item.product?.productNumber === record.productNumber);

    if (isAssetAssigned || isBaseAssigned) return true;

    return false;
  };

  const handlePageChange = (page: number, pageSize: number): void => {
    setTake(pageSize);
    setOffset((page - 1) * pageSize);
  };

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);

    setSearchParams({ ...params, productType: 'physical part' });
  }, []);

  const handleAssignment = (record: ProductResponse): JSX.Element => {
    if (record.productType === 'Physical Part')
      return (
        <Tooltip title="Assigned Install Bases">
          <Button
            style={{ backgroundColor: iconHover === record.productNumber ? '#E5EEFF' : undefined }}
            onMouseLeave={(): void => setIconHover('')}
            onMouseEnter={(): void => setIconHover(record.productNumber)}
            icon={<Lottie loop goTo={241} play={iconHover === record.productNumber} style={{ width: 22, height: 22 }} animationData={baseLottie} />}
            onClick={(): void => nav(`/install-base?productNumberContains=${record.productNumber}`)}
          />
        </Tooltip>
      );
    else
      return (
        <Tooltip title="Intangible Asset Assignments">
          <Button
            style={{ backgroundColor: iconHover === record.productNumber ? '#FFF5E5' : undefined }}
            onMouseLeave={(): void => setIconHover('')}
            onMouseEnter={(): void => setIconHover(record.productNumber)}
            icon={<Lottie loop goTo={65} play={iconHover === record.productNumber} style={{ width: 22, height: 22 }} animationData={iaLottie} />}
            onClick={(): void => nav(`/intangible-assets?productNumberContains=${record.productNumber}`)}
          />
        </Tooltip>
      );
  };

  const columns: ColumnsType<ProductResponse> = [
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      render: (value, record) => <EditProductButton record={record} />
    },
    {
      title: 'Alternate Item Id',
      dataIndex: 'alternateItemId',
      key: 'alternateItemId'
    },
    {
      title: 'Manufacturer',
      key: 'manufacturerName',
      render: (_, record) => <Typography>{record.manufacturer?.name}</Typography>
    },
    {
      title: 'Category',
      key: 'categoryName',
      render: (_, record) => <Typography>{record.category?.name}</Typography>
    },
    {
      title: 'Product Type',
      dataIndex: 'productType',
      key: 'productType'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <Typography>{record.isActive ? 'Active' : 'Inactive'}</Typography>
    },
    {
      title: 'Actions',
      align: 'center',
      render(_, record): JSX.Element {
        return (
          <Space>
            {handleAssignment(record)}
            <DeleteRecordButton
              tooltip={isDeleteDisabled(record) ? 'Cannot delete a product that is currently in use by an intangible asset or install base' : undefined}
              isDisabled={isDeleteDisabled(record)}
              isDeleting={isDeleting}
              deleteRecord={deleteProduct}
              title="Are you sure you want to delete this product?"
              recordToDelete={record.id}
            />
          </Space>
        );
      }
    }
  ];

  if (isLoading || isAssetLoaing || isInstallBaseLoading) return <PageLoader loadingMessage="Loading parts" />;

  if (isError || isBaseError || isIaError) {
    const errData = isError ? (error as FetchBaseQueryError) : isBaseError ? (baseError as FetchBaseQueryError) : (iaError as FetchBaseQueryError);

    return (
      <WimsicalError
        title={(errData.data as string) || 'Oops something went wrong'}
        statusCode={errData.status}
        subTitle={'Please try to reload the app and try again'}
        redirectText={'Reload'}
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
      />
    );
  }

  return (
    <>
      <ProductTypeFilter />
      <Table
        pagination={{ total: totalCount, defaultPageSize: take, onChange: handlePageChange, pageSizeOptions: ['10', '20', '25', '50', '100'] }}
        loading={{ spinning: isFetching || isImporting, indicator: <LoaderWithMessage loadingMessage={isImporting ? importLoadingMessage : 'Loading'} /> }}
        dataSource={data?.data}
        size="small"
        columns={columns}
        scroll={{ x: 'max-content' }}
        rowKey={(item): string => item.productNumber}
        {...rest}
      />
    </>
  );
};
